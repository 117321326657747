<!-- 考试大类 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>

<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      CheckHeader

    },
    data() {
      return {
        title: "机构用户审核权限  / 内江师范学院2021年12月考核招聘艺体类工作人员招聘考试",
        subTitle: "切换报名",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        checked: true,
        defaultvalue: 1,
        currentpage: 1,
        showmodal: false,
        showmodal2:false,
        tableList: [{
            ssjg: "西华大学",
            ssdw: "西华大学宜宾校区",
            dlzh: "106150101",
            yhxm: "李老四",
            shfw: "报名资格|照片",
            shlb: "按区域",
            qzxh: "--",
            shdq: "成都市",
            yjrws: "345"
          },
          {
            ssjg: "西华大学",
            ssdw: "西华大学宜宾校区",
            dlzh: "106150101",
            yhxm: "李老四",
            shfw: "报名资格|照片",
            shlb: "按区域",
            qzxh: "--",
            shdq: "成都市",
            yjrws: "345"
          },
          {
            ssjg: "西华大学",
            ssdw: "西华大学宜宾校区",
            dlzh: "106150101",
            yhxm: "李老四",
            shfw: "报名资格|照片",
            shlb: "按区域",
            qzxh: "--",
            shdq: "成都市",
            yjrws: "345"
          },
        ],
        tabList2: [{
            ssdw: "西华大学宜宾校区",
            dlzh: "106150101",
            yhxm: "李老四"
          },
          {
            ssdw: "西华大学宜宾校区",
            dlzh: "106150101",
            yhxm: "李老四"
          },
          {
            ssdw: "西华大学宜宾校区",
            dlzh: "106150101",
            yhxm: "李老四"
          }
        ]

      };
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="check-table-top">
              <div class="flexList" style="flex: 1;">
                <b-dropdown size="sm" class="mr-2" variant="outline-info" style="min-width: 7%;">
                  <template v-slot:button-content>
                    所有机构

                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item-button>
                    1001四川省人力资源和社会保障厅
                  </b-dropdown-item-button>
                  <b-dropdown-item-button> 5102西南交通大学</b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown size="sm" class="mr-2" variant="outline-info" style="min-width:7%;">
                  <template v-slot:button-content>
                    所属单位
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item-button> 全国职业（执业）资格考试</b-dropdown-item-button>
                  <b-dropdown-item-button> 全国专业技术资格考试</b-dropdown-item-button>
                </b-dropdown>
                <input placeholder="关键字" class="h30 form-control border-blue mr-2 w-10" maxlength="50" />

                <button type="button" class="btn btn-info h30 flexList mr-2 w-xs"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button v-b-modal.add-insiti-verify variant="outline-info" class="flexList mr-2 condition w-md"><i
                    class="iconfont icon-plus-circle1 mr-2"></i>添加用户</b-button>
                <b-button v-b-modal.handleMore variant="outline-info" class="flexList mr-2 condition w-md"><i
                    class="iconfont icon-antOutline-usergroup-addCopy mr-2"></i>批量设置</b-button>

              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover  ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th style="width: 8%;">所属机构</th>
                    <th>所属单位</th>
                    <th style="width:7%;">登录账号</th>
                    <th style="width: 5%;">用户姓名</th>
                    <th style="width: 8%;">审核范围</th>
                    <th style="width: 7%;">审核类别</th>
                    <th style="width: 5%;">起止序号</th>
                    <th style="width: 8%;">审核地区</th>
                    <th style="width: 5%;">预计任务数</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.ssjg}}</td>
                    <td><a href="" class="font-blue">{{obj.ssdw}}</a></td>
                    <td>{{obj.dlzh}}</td>
                    <td>{{obj.yhxm}}</td>
                    <td>{{obj.shfw}}</td>
                    <td>{{obj.shlb}}</td>
                    <td>{{obj.qzxh}}</td>
                    <td>{{obj.shdq}}</td>
                    <td class="text-center">
                      {{obj.yjrws}}
                    </td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"></i><i
                        class="iconfont icon-riLine-delete-bin-line align-middle mr-1"></i></td>
                  </tr>



                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <div class="all-count mr-4">共有 100条，20条/页，前往<div class="link-page m-2"></div>页</div>
              <b-pagination v-model="defaultvalue" :total-rows="50" :per-page="10" aria-controls="my-table">
              </b-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 添加机构审核用户 -->
    <b-modal id="add-insiti-verify" v-model="showmodal" centered title="添加机构审核用户" title-class="font-18" hide-footer>
      <div>
        <div class="d-flex check-distri mb-3"><label>审核范围：</label>
          <div class=" col-sm-10 p-0">
            <div class="flexList mb-2">
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">报名资格审核
                </label>
              </div>
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">报名资格审核</label>
              </div>
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">学历审核</label>
              </div>
            </div>
            <div class="flexList">
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">早出报名数据

                </label>
              </div>
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">修改审核结果</label>
              </div>
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">锁定审核</label>
              </div>
            </div>


          </div>
        </div>
        <div class="check-table-top mb-3">
          <div class="flexList">
            <b-dropdown size="sm" class="mr-2" variant="outline-info" style="min-width: 7%;">
              <template v-slot:button-content>
                所有机构

                <i class="mdi mdi-chevron-down"></i>
              </template>
              <b-dropdown-item-button>
                1001四川省人力资源和社会保障厅
              </b-dropdown-item-button>
              <b-dropdown-item-button> 5102西南交通大学</b-dropdown-item-button>
            </b-dropdown>
            <b-dropdown size="sm" class="mr-2" variant="outline-info" style="min-width:7%;">
              <template v-slot:button-content>
                所属单位
                <i class="mdi mdi-chevron-down"></i>
              </template>
              <b-dropdown-item-button> 全国职业（执业）资格考试</b-dropdown-item-button>
              <b-dropdown-item-button> 全国专业技术资格考试</b-dropdown-item-button>
            </b-dropdown>
            <input placeholder="关键字" class="h30 form-control border-blue mr-2 w-30" maxlength="50" />

            <button type="button" class="btn btn-info h30 flexList mr-2 w-xs"><i
                class="iconfont mr-2 icon-mb-search"></i>查询</button>
          </div>
        </div>
        <div class="" style="overflow-y: auto;max-height: 200px;">
          <table class="table light-table table-hover border ">
            <thead class="thead-light">
              <tr>
                <th style="width: 15%;">
                  <div class="form-check mr-2 "><input type="checkbox" value="" id="defaultCheck1"
                      class="form-check-input"><label for="defaultCheck1" class="form-check-label" style="line-height: 23px;">全选
                    </label>
                  </div>
                </th>
                <th>序号</th>
                <th>所属单位</th>
                <th>登录账号</th>
                <th>用户姓名</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(obj,index) in tabList2" :key="index">
                <td class="text-center"><input type="checkbox" value=""></td>
                <td>{{index+1}}</td>
                <td>{{obj.ssdw}}</td>
                <td>{{obj.dlzh}}</td>
                <td>{{obj.yhxm}}</td>
              </tr>
            </tbody>
          </table>
        </div>




      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3">确定添加</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 批量预置 -->
    <b-modal id="handleMore" v-model="showmodal2" centered title="批量预置审核账号" title-class="font-18" hide-footer>
      <div>
        <div class="d-flex check-distri mb-3"><label>审核范围：</label>
          <div class=" col-sm-10 p-0">
            <div class="flexList mb-2">
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">报名资格审核
                </label>
              </div>
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">报名资格审核</label>
              </div>
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">学历审核</label>
              </div>
            </div>
            <div class="flexList">
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">早出报名数据

                </label>
              </div>
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">修改审核结果</label>
              </div>
              <div class="form-check mr-2 w-30"><input type="checkbox" value="" id="defaultCheck1"
                  class="form-check-input"><label for="defaultCheck1" class="form-check-label">锁定审核</label>
              </div>
            </div>


          </div>
        </div>

        <div class="d-flex check-distri mb-3"><label>账号规则：</label>
          <div class=" col-sm-10 p-0">
         
              <div class="form-check  mr-2 mb-2"><input type="radio" name="exampleRadios" id="exampleRadios1" value="option1"
                  checked="checked" class="form-check-input"><label for="exampleRadios1"
                  class="form-check-label">采用单位编号+序号，如：10613010102
                </label></div>
              <div class="form-check  mr-2 mb-2"><input type="radio" name="exampleRadios" id="exampleRadios1" value="option1"
                  checked="checked" class="form-check-input"><label for="exampleRadios1"
                  class="form-check-label">采用单位名称+序号，如：达州市教育局01
                </label></div>



          </div>
        </div>




      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3">确定添加</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal2 = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->

  </Layout>
</template>
